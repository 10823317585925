var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "justify-center primary--text my-4 text-h5 text-sm-h4"
  }, [_vm._v(" Page Not Found ")]), _c('v-card-subtitle', {
    staticClass: "text-center black--text text-h6 text-sm-h5"
  }, [_vm._v(" Oops! The requested URL was not found on this server. ")]), _c('v-card-actions', {
    staticClass: "justify-center mb-8"
  }, [_c('vx-btn', {
    attrs: {
      "color": "primary",
      "to": "/"
    }
  }, [_vm._v("Back to home")])], 1), _c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "width": "400",
      "height": "400",
      "src": require("@/assets/images/404.svg")
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }